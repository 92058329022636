/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-07",
    versionChannel: "nightly",
    buildDate: "2023-07-07T00:20:07.173Z",
    commitHash: "c60742428c75fe42476dfe66d6f4f215bf8b773f",
};
